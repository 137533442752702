<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="clickEvent">
            <el-tab-pane label="基本信息" name="1" :link="`/manage/workbench/site/baseInfo`"></el-tab-pane>
            <template v-if="$store.state.userInfo && $store.state.userInfo.userType == 2" >
                <el-tab-pane label="歌曲管理" name="2" :link="`/manage/workbench/site/music`"></el-tab-pane>
                <!-- <el-tab-pane label="发行管理" name="5" :link="`/manage/workbench/site/release`"></el-tab-pane> -->
            </template>
            <el-tab-pane label="相册管理" name="3" :link="`/manage/workbench/site/media`"></el-tab-pane>
            <el-tab-pane label="视频管理" name="4" :link="`/manage/workbench/site/video`"></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    props:{
        name:{
            type: String,
            default: '1'
        }
    },
    data(){
        return{
            activeName: this.name,
        }
    },
    methods:{
        clickEvent(tab){
            if (tab.$attrs.link) this.$router.push(tab.$attrs.link)
        }
    }
}
</script>

<style lang="scss" scoped>
.el-tabs{
    margin:-10px 0 10px 0;
}
</style>