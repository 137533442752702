<template>
    <div>
        <tab name="5"></tab>
        <div class="page-content-box">
            前往
            <el-link type="primary" @click="$release('/manage/album/list')">
                <i class="iconfont">&#xe63a;</i> 秀动发行 
            </el-link>
            查看播放、结算数据
        </div>
    </div>
</template>

<script>
import Tab from './components/tab'

export default {
    components: { Tab },
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.page-content-box {
    display: flex;
    align-items: center;
    line-height: normal;
    .iconfont{
        font-size: 14px;
    }
}
</style>